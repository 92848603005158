<template>
  <div>
    <header class="d-md-none mb-3">
      <div class="background-header"></div>
      <div class="content container">
        <div class="row">
          <div class="col d-flex justify-content-between align-items-center">
            <i class="ri-arrow-left-line ri-xl fw-500 text-white" role="button" @click="$router.replace({name: 'ProfilOrder'})"></i>
            <div class="content-image d-flex gap-4 align-items-center">
              <button
                v-if="detail.patients && detail.patients[0].status_bayar == 'Belum Dibayar'"
                class="btn bg-transparent color-neutral-600 align-items-center py-1"
                @click.prevent="openPopupDelete">
                <img
                  src="@/assets/icon/trash-3.svg"
                  alt="Hapus"
                />
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex align-items-end justify-content-between">
            <div class="order-group d-flex flex-column">
              <span class="order-label fw-400 text-white mb-2">No. Pesanan</span>
              <span class="order-code fw-700 text-white">{{ $route.params.id_order }}</span>
            </div>
            <span class="order-date fw-400 text-white">{{ detail.patients ? detail.patients[0].tgl_daftar : 'Loading...' }}</span>
          </div>
        </div>
      </div>
      <div class="info container px-3">
        <div class="row">
          <div class="col">
            <div class="card shadow border-0">
              <div class="card-body row p-0">
                <div class="info-group col d-flex align-items-center">
                  <div class="initial-wrapper d-flex align-items-center justify-content-center bg-info-500 rounded-circle">
                    <span class="initial-value fw-400 fs-14 lh-18 text-white">
                      {{ detail.patients && getInitialName(detail.patients[0].ap_nama) }}
                    </span>
                  </div>
                  <div class="value-wrapper d-flex flex-column">
                    <span class="value-wrapper-label fw-400 color-neutral-600">Nama Pasien</span>
                    <label class="value-wrapper-name fw-700 color-neutral-900">{{ detail.patients ? detail.patients[0].ap_nama : 'Loading...' }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="px-3">
      <div v-if="loading.detail" class="item-content">
        <div class="d-flex align-items-center justify-content-center" style="height: 20rem">
            <div class="not-empty d-flex flex-column align-items-center pt-3">
                <div class="mb-4">
                  <img src="@/assets/icon/empty.png" alt="">
                </div>
                <div class="fm-poppins text-title-active fw-600 fs-32">Loading..</div>
            </div>
        </div>
      </div>

      <div v-else-if="detail.length === 0" class="item-content">
          <div class="d-flex align-items-center justify-content-center" style="height: 20rem">
              <div class="not-empty d-flex flex-column align-items-center pt-3">
                  <div class="bd-highlight mb-4">
                      <img src="@/assets/icon/not-found.png" alt="">
                  </div>
                  <div class="bd-highlight">
                      <div class="fm-poppins text-title-active fw-600 fs-32">Tidak Ada Hasil</div>
                  </div>
                    <div class="bd-highlight w-75 text-center">
                      <p class="text-nunito text-body-light fw-400 fs-18">Sepertinya kami tidak dapat menemukan data pesanan Anda.</p>
                  </div>
              </div>
          </div>
      </div>

      <div v-else>
        <div class="row d-none d-md-block mt-3">
          <div class="col-md-7">
              <div class="page-title d-flex justify-content-between align-items-center pb-2">
                  <div class="bd-highlight d-flex align-items-center">
                      <i class="ri-arrow-left-line pe-4" role="button" @click="$router.replace({name: 'ProfilOrder'})"></i>
                      <div class="text-title-active text-poppins fw-600 fs-16">Pesanan</div>
                  </div>
                  <div class="bd-highlight">
                      <div class="fm-nunito text-title-active fw-600 fs-16">{{ $route.params.id_order }}</div>
                  </div>
              </div>
              <div v-if="!paymentStatus" class="notification pt-3">
                  <div class="card-info p-2 py-3 bd-highlight d-flex flex-row align-items-center bg-warning-disable border-0">
                      <p class="fm-nunito fst-normal fw-600 fs-14 lh-14 mb-0 px-2 text-warning">Pesanan ini belum dibayar, silahkan lanjutkan ke pembayaran jika ingin melanjutkan</p>
                  </div>
              </div>
          </div>
        </div>
          <div class="row">
            <div class="col-md-7">
              <div class="location">
                <div class="mb-2">Lokasi Klinik</div>
                <router-link
                  :to="{
                    name: 'SelectBranch',
                    query: {
                      redirectTo: $route.path
                    }
                  }"
                  class="address-box bg-neutral-100 d-flex align-items-center gap-2 mb-3">
                  <div
                    class="icon-wrapper bg-neutral-300 d-flex align-items-center justify-content-center rounded-circle"
                  >
                    <img
                      src="@/assets/icon/location.png"
                      alt=""
                    />
                  </div>
                  <div class="address-box-info d-flex flex-column">
                    <strong class="address-box-info-name fw-700 color-neutral-900">{{ (detail.patients) ? detail.patients[0].cabang_periksa:'Loading ...' }}</strong>
                    <small class="address-box-info-address fw-400 color-neutral-600">{{ (detail.patients) ? detail.patients[0].cabang_alamat:'Loading ...' }}</small>
                  </div>
                </router-link>
              </div>
              <div class="order">
                <div class="mb-2">Pesanan</div>
                <div class="list-service">
                  <div
                    v-for="service in detail.services"
                    :key="service"
                    class="service d-flex justify-content-between align-items-center">
                    <div class="service-group d-flex align-items-center gap-2">
                      <div class="service-icon">
                        <img
                          src="@/assets/icon/stethoscope-1.svg"
                          alt=""
                        />
                      </div>
                      <div class="service-info">
                        <span class="service-title fw-700 color-neutral-900">{{ service.nama }}</span>
                        <p class="service-price fw-400 color-secondary-500 mb-0">Rp. {{ parseFloat(service.harga).toLocaleString('id-ID') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="payment bg-neutral-100">
                <div class="total-payment d-flex align-items-center justify-content-between">
                  <div class="d-flex flex-column">
                    <span class="fw-400 fs-12 lh-14 color-neutral-600 mb-1">Total Pembayaran</span>
                    <p class="fw-700 fs-14 lh-24 color-neutral-900 mb-0">
                      {{ detail.patients ? 'Rp. ' + parseInt(detail.patients[0].nominal)?.toLocaleString('id-ID') : 'Loading ...' }} ({{ detail.patients[0].status_bayar }})
                    </p>
                  </div>
                  <div
                    v-if="detail.patients[0].status_bayar === 'Sudah Dibayar'"
                    class="icon-wrapper">
                    <img
                      src="@/assets/icon/stamp-paid.svg"
                    />
                  </div>
                </div>
              </div>
              <div v-if="detail.patients[0].status_bayar == 'Belum Dibayar'" class="notification bg-info-50 d-flex align-items-center gap-3">
                <div class="notification-icon">
                  <img
                    src="@/assets/icon/exclamation-mark.svg"
                    alt=""
                  >
                </div>
                <span class="notification-desc fw-400 fs-12 lh-14 color-info-500">
                  Yuk selesaikan pesananmu, lanjutkan ke pembayaran
                </span>
              </div>
              <!-- <div v-if="!showQr" class="card-info bg-danger p-3 mt-4 mb-0">
                <p class="text-white fm-nunito fw-bolder fst-normal fs-14 lh-19 mb-0">PERINGATAN: Uang yang sudah dibayarkan tidak dapat ditarik kembali !!!</p>
              </div> -->

              <!-- <span class="d-block text-center text-error fm-nunito fs-16 fw-600 mt-2">
                {{ errorMessage }}
              </span> -->
              <div v-if="detail.patients && detail.patients[0].status_bayar == 'Belum Dibayar'" class="bottom-bar my-sm-3">
                <button
                  v-if="!showQr"
                  class="btn btn-orange my-md-3 fw-600 fs-14 lh-20 text-white mb-0"
                  @click="pay()"
                >
                  Bayar Pesanan
                </button>
              </div>
            </div>
            <div class="col-md-5 pb-5 pb-sm-0 pb-md-0 mb-5 mb-sm-0 mb-md-0">
              <div
                v-if="showQr"
                class="card-info p-3 bd-highlight d-flex flex-column align-items-center">
                <div class="bd-highlight pt-1 pb-3">
                  <img
                    :src="'https://api.qrserver.com/v1/create-qr-code/?color=007A98&data='+$route.params.id_order"
                    alt="Qr Code"
                  >
                </div>
                <div class="bottom-bar w-100 px-3 py-2 px-md-0 py-md-0">
                <div
                  v-if="!!!parseInt(detail.patients[0].status_datang)"
                  class="bd-hightlight w-100">
                  <div class="card-info p-3 bd-highlight d-flex flex-row align-items-center bg-blue-soft-pastel border-0 text-primary">
                      <i class="ri-information-line ri-xl"></i>
                      <p class="fm-nunito fst-normal fw-600 lh-14 mb-0 px-2" style="font-size: 13px">Tunjukkan QR Code ini ke resepsionis</p>
                  </div>
                </div>
                <router-link
                  v-else
                  :to="{ name: 'Kkp', params: { id_order: $route.params.id_order }}"
                  class="btn w-100 d-block btn-primary w-100 pt-2 pb-2">
                  Lihat KKP
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal modal-delete fade"
    :id="`modal-delete-${$route.params.id_order}`"
    tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content border-0">
        <div class="modal-body d-flex flex-column p-0">
          <div class="modal-body-group d-flex align-items-center gap-3">
            <div class="modal-body-icon">
              <img src="@/assets/icon/trash-2.svg" alt="">
            </div>
            <div class="modal-body-info text-start">
              <span class="modal-body-label fw-700 fs-14 lh-24 color-neutral-900">
                Hapus pesanan {{ detail.patients ? detail.patients[0].ap_nama : 'Loading...' }}?</span>
              <p class="modal-body-desc fw-400 fs-12 lh-22 color-neutral-600 mb-0">Anda akan menghapus pesanan ini dari daftar pesanan</p>
            </div>
          </div>
          <div class="group-button d-flex align-items-center gap-3">
            <button
              type="button"
              class="btn btn-primary-outline"
              data-bs-dismiss="modal">
              <span class="fw-600 fs-14 lh-20">Nanti Saja</span>
            </button>
            <button
              @click="deleteOrder(route.params.id_order)"
              type="button" class="btn btn-danger">
              Hapus Pesanan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
</template>

<script>
import { Modal } from 'bootstrap'
import { useRoute } from 'vue-router'
import { ref, computed, onMounted } from 'vue'
import { detail, getDetail, loading, deleteOrder } from '@/core/profile'
// import AppBar from '@/components/AppBar.vue'

export default {
  components: { },
  setup () {
    const errorMessage = ref('')
    const route = useRoute()

    onMounted(async () => {
      try {
        await getDetail({
          kode: route.params.id_order
        })
      } catch (error) {
        console.error('Error get detail = ', error)
        errorMessage.value = 'error: ' + error.response.data.message
      }
    })

    const paymentStatus = computed(() => {
      if (detail.value.patients && detail.value.patients[0].status_bayar === 'Belum Dibayar') {
        return false
      }
      return true
    })

    const showQr = computed(() => {
      if (detail.value.patients && detail.value.patients[0].status_bayar === 'Belum Dibayar' && detail.value.patients[0].pembayaran === 'Bayar Online') {
        return false
      }
      return true
    })

    const diskon = computed(() => {
      if (detail.value.patients && detail.value.services) {
        return detail.value.services.reduce((acc, item) => acc + parseInt(item.harga), 0) - detail.value.patients[0].nominal
      }
      return 0
    })

    const pay = () => {
      const token = detail.value.patients[0].token

      if (!token) {
        errorMessage.value = 'Data token tidak ditemukan'
        return
      }

      window.getSnap().then(snap => {
        snap.pay(token, {
          onSuccess: () => {
            getDetail({
              kode: route.params.id_order
            })
          }
        })
      })
    }

    const openPopupDelete = () => {
      const modal = new Modal(
        document.querySelector(`#modal-delete-${route.params.id_order}`)
      )

      modal.show()
    }

    const getInitialName = (fullname) => {
      const fullName = fullname.split('')
      const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0)

      return initials.toUpperCase()
    }

    return {
      detail,
      paymentStatus,
      loading,
      diskon,
      showQr,
      pay,
      errorMessage,
      deleteOrder,
      openPopupDelete,
      getInitialName
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  height: 234px;
  position: relative;

  .background-header {
    width: 100%;
    height: 212px;
    position: absolute;
    z-index: 1;
    background: #007A98;
  }

  .content {
    position: absolute;
    z-index: 2;
    height: 80px;

    .row {
      @media (max-width: 767px) {
        height: 100%;
      }

      .order {
        &-label,
        &-date {
          font-size: 14px;
          line-height: 24px;
        }

        &-code {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
  }

  .info {
    position: absolute;
    z-index: 3;
    bottom: 0;

    .card {
      padding: 12px;
      border-radius: 12px;

      .card-body {
        .info-group {
          gap: 8px;

          .initial-wrapper {
            width: 36px;
            height: 36px;
          }

          .value-wrapper {
            gap: 2px;

            &-label {
              font-size: 12px;
              line-height: 14px;
            }

            &-name {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }

        .info-group:first-child {
          box-shadow: inset -1px 0px 0px rgba(242, 242, 242, 0.8);
        }
      }
    }
  }
}

.location {
  .address-box {
    padding: 9px 8px;
    border-radius: 12px;

    .icon-wrapper {
      width: 34px;
      height: 34px;

      img {
        width: 18px;
        height: auto;
      }
    }

    &-info {
      &-name {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 4px;
      }

      &-address {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

.service {
  box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;

  &-icon {
    width: 42px;
    height: 42px;
  }

  &-group {
    padding: 12px 0;
  }

  &-title {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 2px;
  }

  &-price {
    font-size: 14px;
    line-height: 24px;
  }
}

.service:last-child {
  box-shadow: unset;
}

.payment {
  padding: 13px 13px 13px 20px;
  margin-bottom: 16px;
}

.notification {
  padding: 10px;
  border-radius: 12px;

  &-desc {
    @media (max-width: 520px) {
      max-width: 281px;
    }
  }
}

.modal-delete {
  .modal-dialog {
    @media (max-width: 520px) {
      position: fixed;
      top: auto;
      right: auto;
      left: auto;
      bottom: 0;
      margin: 0;
    }

    .modal-content {
      width: 100% !important;
      padding: 22px 18px;
      border-radius: 20px !important;

      @media (max-width: 520px) {
        border-radius: 20px 20px 0px 0px !important;
      }

      .modal-body {
        .group-button {
          margin-top: 32px;

          button {
            width: 100%;
            padding: 10px;
          }
        }
      }
    }
  }
}

.modal.fade .modal-dialog {
  transform: translate3d(0, 100vh, 0);
  transition: transform 0.5s ease-out;
}

.modal.show .modal-dialog {
  transform: translate3d(0, 0, 0);
}

// .card-info {
//     border: 1px solid #EFF0F7;
//     box-sizing: border-box;
//     border-radius: 10px;
// }

// .card-order {
//     border: 1px solid #EEEFF7;
//     box-sizing: border-box;
//     border-radius: 10px;
// }

// .location-info .card-info {
//     background: #F7F7FC;
// }

// .icon-bg {
//     width: 2.5rem;
//     border-radius: 8px;
//     padding-top: 0.75rem;
//     padding-bottom: 0.75rem;
// }

// .btn-complete-payment {
//   width: 100%;

//   @media (min-width: 520px) {
//     width: 65%;
//     border-radius: 10px;
//     padding-top: 0.75rem;
//     padding-bottom: 0.75rem;
//   }
// }

.bottom-bar {
  @media (max-width: 575px) {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 18px;
    border-top: 1px solid rgb(240, 240, 240);
  }

  .btn-orange {
    width: 100%;
    padding: 10px 0;
  }
}

// .card.summary-order {
//   border: 1px solid #EEEFF7;
//   box-sizing: border-box;
//   border-radius: 10px;
// }
</style>
